main {
  display: flex;
  min-height: 90vh;
  align-items: center;
  flex-direction: column;
  margin: 1rem 12rem;
  gap: 1rem;
}

a:visited {
  color: var(--black);
}


a {
  color: inherit;
  text-decoration: none; 
}

ul {
  list-style: none;
}

/* ---------HOME---------- */

.youtubeClip {
  width: 100%;
}

.homeSection-txt {
  font-size: larger;
  font-weight: bold;
  background: linear-gradient(130deg, var(--col_1) 0%, var(--col_3) 100%);
  color: var(--white);
  border-radius: 10px;
  padding: 2rem 2rem;

  display: flex;
  flex-direction: column;
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.image-gallery > li {
  flex: 1 1 auto;
  height: 300px;
  position: relative;
}

.image-gallery::after {
  content: "";
  flex-grow: 999;
}

.image-gallery li img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  border-radius: 5px;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* ---------ABOUT---------- */
.aboutImg {
  display: flex;
}
.aboutImg img {
  height: auto;
  width: 100%;
  border-radius: 10px;
}

.aboutList-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: .5rem;
}

.aboutList-card {
  flex: 1 0 25%;
  background-color: var(--white);
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0, 0.1);
  min-width: 140px;
  max-width: 350px;
  pointer-events: none;
  flex-shrink: 1;
}

.aboutList-inside {
  text-decoration: none;
  color: var(--black);
}

.aboutList-inside img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}

/* ---------BLOG---------- */

.blogList-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  overflow-wrap: anywhere;
}

.blogList-card {
  display: flex;
  background-color: var(--white);
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0, 0.1);
  width: 25%;
  height: auto;
  min-width: 150px;
  max-width: 450px;
  pointer-events: none;
  flex-grow: 1;
}

.blogList-inside {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: var(--black);
}

.blogList-inside img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

/* ---------CONTACT PAGE---------- */

.contact {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 2rem;
}

.socials {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.socials svg {
  color: var(--col_3);
  height: 35px;
  width: 35px;
  margin-right: 10px;
}

.largeImg {
  max-width: 40rem;
}
.largeImg img {
  width: 100%;
  height: auto;
}

@media (max-width: 1200px) {
  main {
    margin: 1rem 4rem;
    gap: .8rem;
  }

  .blogList-wrapper {
    gap: .5rem;
  }
}


@media (max-width: 768px) {

  main {
    font-size: smaller;
    margin: .8rem .8rem;
  }
  
  .aboutList-wrapper {
    gap: .5rem;
  }

  .aboutList-card {
    padding: 10px;
    height: auto;
    width: 28%;
    min-width: 80px;
  }

  .blogList-wrapper {
    width: 100%;
  }
  .blogList-card {
    display: flex;
  }
  .blogListImg {
    width: 100%;
    object-fit: contain;
    block-size: auto;
  }

}

/* @media (max-width: 480px) {
  .aboutList-wrapper {
    flex-direction: column;
    width: 100%;
    align-content: center;
  }
  .aboutList-card {
    width: 100%;
    max-width: 100%;
  }
} */


