* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: 'Poppins', sans-serif, Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
}

body {
  background-color: var(--color-background);
  color: var(--black);
  transition: background-color 0.3s ease, color 0.3s ease;
}

:root {
  --white: #ffffff;
  --black: #000;

  --navbar-color: #fff;

  --color-background: #F5F1F9;
  --primary-dark: #1d1d1d;

  --col_1: #56D2AD;
  --col_2: #00d4f5;
  --col_3: #FC2BC4;
}

.dark-mode {
  --white: #000;
  --black: #ffffff;

  --color-background: #1d1d1d;
  --primary-dark: #F5F1F9;
}

@media (max-width: 768px) {
  html {
    font-size: 14px;
  }
}

