.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: linear-gradient(90deg, var(--col_1) 0%, var(--col_3) 100%); */
    background: var(--col_1);
    height: 4rem;
    padding-left: 1rem;
    padding-right: 1rem;
    z-index: 10;
}

.nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: inherit;
    margin: 0 auto;
}

.nav-logo {
    display: inline-flex;
}

.nav-logo img:hover {
    animation: tilt-shaking 0.30s infinite;
}

@keyframes tilt-shaking {
    0% { transform: rotate(0deg);}
    25% { transform: rotate(5deg);}
    50% { transform: rotate(0deg);}
    75% { transform: rotate(-5deg);}
    100% { transform: rotate(0deg);}
    
}

.nav-logo img {
    height: 3.5rem;
    width: 100%;
}

.nav-menu {
    display: flex;
    flex-direction: row;
    list-style: none;
    text-align: center;
    padding: 0;
    padding-inline-start: 0;
    margin: 0;
}

.nav-menu li {
    list-style: none;
    color: var(--navbar-color);
    margin-right: 1rem;
    margin-left: 1rem;
    line-height: 40px;
}

.nav-menu li a.active {
    color: var(--navbar-color);
    filter: brightness(1.5);
    -webkit-text-stroke: thin;
}

.nav-menu ul {
    display: flex;
    height: inherit;
    align-items: center;
    text-decoration: none;
    transition: 0.4s;
}

.nav-menu li a {
    position: relative;
    text-decoration: none;
    font-size: 18px;
    padding: 8px;
}

/* .nav-menu li:after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-menu li:hover:after {
    width: 100%;
    background: var(--navbar-color);
} */

.nav-menu li a:not(.active):visited {
    color: inherit;
}

/* ----------ICONS------------ */

.hamburger {
    display: none;
    color: var(--navbar-color);
}


.darkModeDropdown {
    display: none;
}

.darkMode {
    display: block;
    position: relative;
    line-height: 0;
    padding: 0;
    margin: 0;
}

/* ----------FOOTER------------ */

footer {
    position: relative;
    display: block;
    bottom: 0;
    width: 100%;
    background-color: #363636;
    padding: 1rem;
}

.content {
    color: #fff;
    font-size: 12px;
    text-align: center;
}

/*------------MEDIA----------------*/

@media (max-width: 768px) {

    .nav-container {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    /* .nav-logo img {
        display: none;
    } */

    .nav-menu {
        display: none;
        margin-block-start: 0;
        top: 0;
        right: -110%;
        height: 100%;
        padding-top: 2rem;
        position: fixed;
        flex-direction: column;
        padding-inline-start: 0;
    }

    .nav-menu-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .nav-menu ul {
        flex-direction: column;
    }

    .nav-menu li {
        padding: 1rem;
    }

    .nav-menu.active {
        display: flex;
        margin-block-start: 0;
        right: 0;
        width: 100%;
        background: var(--col_1);
        opacity: 1;
        z-index: 1;
        overflow-y: hidden;
    }

    .nav-links {
        padding: 1.5rem;
        width: 100%;
        display: table;
    }

    .hamburger {
        display: flex;
        cursor: pointer;
        z-index: 9;
    }

    .darkMode {
        display: none;
    }

    .darkModeDropdown {
        display: block;
    }
    
}
